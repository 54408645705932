// import { createClient } from "@sanity/client";
// import imageUrlBuilder from "@sanity/image-url";
// const client = createClient({
//   projectId: "vyxv7r0t",
//   dataset: "production",
//   apiVersion: "2021-09-29",
//   useCdn: true,
//   ignoreBrowserTokenWarning: true,
//   token:
//     "skBx4hY5I1qjNAhzXQWPudtyl2SSqZFS3Mzn1CgHsVRzrnVCfXY8Cc5WXiJiccui3QR7JId9kHUsEdchr2e7xAxqm4pPkSem8Ne6pO8B6iPqs7vO8udKxLCAyFaE3z22cVM3sqZX3COJbZ87RYHuo8SjsFnvO3EMrinu3qNggnGGHIzhPQ79",
// });

// const builder = imageUrlBuilder(client);

// export const urlFor = (source) => builder.image(source);

// export default client;

import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const client = createClient({
  projectId: process.env.REACT_APP_PROJECT_ID,
  dataset: "production",
  apiVersion: "2021-09-29",
  useCdn: true,
  ignoreBrowserTokenWarning: true,
  token: process.env.REACT_APP_SANITY_API_TOKEN,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => {
  if (!source) return "/path/to/default-image.jpg"; // Fallback for missing images
  return builder
    .image(source)
    .width(800) // Default width for large screens
    .height(800) // Default height
    .quality(20) // Compression quality (75 is a good balance)
    .auto("format"); // Automatically choose WebP or JPEG
};

export const urlForLQIP = (source) => {
  if (!source) return "/path/to/default-placeholder.jpg"; // Fallback for missing images
  return builder
    .image(source)
    .blur(10) // Apply blur effect
    .quality(10) // Very low quality for faster loading
    .auto("format"); // Automatically choose WebP or JPEG
};

export default client;
