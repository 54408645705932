import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchSmartPrice } from "../../services/akbarApi";
export const useSmartPrice = () => {
  const navigate = useNavigate();
  const generateOrderID = () => `ORD-${Date.now()}`;

  const handleFetchSmartPrice = async (trips, TUI) => {
    const trip = trips.map((trip) => ({
      Amount: 0, // Fallback to 0 if NetFare is undefined
      Index: trip?.Index || "N/A", // Fallback to "N/A" if Index is undefined
      OrderID: 1, // Static value
      TUI: TUI, // Assigned value
    }));

    sessionStorage.setItem("trips", JSON.stringify(trip));

    try {
      navigate(`/booking/${TUI}`);
    } catch (err) {
    } finally {
    }
  };
  return { handleFetchSmartPrice };
};
