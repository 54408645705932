import React from "react";
import DatepickerContainer from "./home/flight/DatepickerContainer";
import { useSearchFlights } from "../context/SearchFlightsContext";
import { useSearchType } from "../context/searchTypeContext";

export default function DateContainer() {
  const { searchType } = useSearchFlights();
  const { outboundDate, setOutboundDate, inboundDate, setInboundDate } =
    useSearchFlights();
  return (
    <>
      <DatepickerContainer
        title="placeGo"
        keyName="outboundDate"
        startDate={outboundDate}
        setStartDate={setOutboundDate}
      />
      {searchType && searchType !== "ON" && (
        <DatepickerContainer
          title="placereturn"
          keyName="inboundDate"
          startDate={inboundDate}
          setStartDate={setInboundDate}
        />
      )}
    </>
  );
}
