import axios from "axios";
const FlightURL = "https://apiflight.flymoons.com";
const clientID = localStorage.getItem("clientId");

// export const generateSignature = async () => {
//   try {
//     const response = await axios.post(
//       "https://b2bapiutils.benzyinfotech.com/Utils/Signature",
//       {
//         MerchantID: "300",
//         ApiKey: "kXAY9yHARK",
//         ClientID: "bitest",
//         Password: "staging@1",
//         AgentCode: "",
//         BrowserKey: "caecd3cd30225512c1811070dce615c1",
//         Key: "ef20-925c-4489-bfeb-236c8b406f7e",
//       }
//     );

//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const generateSignature = async () => {
  try {
    const response = await axios.post(`${FlightURL}/flight/signature`);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const initiateExpressSearch = async (searchData) => {
  const token = localStorage.getItem("Token");

  try {
    // Retrieve Token from local storage

    if (!token) {
      console.warn("Token is missing. Ensure you have a valid token.");
      return;
    }

    // Call the API with the Token in the Authorization header
    const response = await axios.post(
      `${FlightURL}/flight/express-search`,
      searchData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // Include the token in headers
        },
      }
    );

    if (response.data.TUI) {
      console.log("ExpressSearch Successful: ", response.data);

      return response.data;
    } else {
      console.warn("No TUI received. Check the response.");
    }
  } catch (error) {
    console.error("Error initiating ExpressSearch: ", error.message);
  }
};

export const fetchSearchResults = async (TUI) => {
  const token = localStorage.getItem("Token");
  const ClientID = localStorage.getItem("ClientID");

  if (!TUI || !ClientID) {
    throw new Error(
      "TUI or ClientID is missing. Please initiate a search first."
    );
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/get-exp-search`,
      { TUI, ClientID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Save the search results
  } catch (error) {
    throw new Error("Error fetching search results:", error.message);
  }
};
export const fetchWebSettings = async () => {
  const token = localStorage.getItem("Token");
  const ClientID = localStorage.getItem("ClientID");
  const TUI = localStorage.getItem("TUI");
  if (!TUI || !ClientID) {
    throw new Error(
      "TUI or ClientID is missing. Please initiate a search first."
    );
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/webSettings`,
      { TUI, ClientID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Save the search results
  } catch (error) {
    throw new Error("Error fetching search results:", error.message);
  }
};

export const fetchSmartPrice = async (payload) => {
  const token = localStorage.getItem("Token");

  if (!token) {
    throw new Error("Authorization token is required.");
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/smart-pricer`, // Replace with your actual endpoint
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.Message) {
      return response.data?.Data;
    } else {
      throw new Error("Failed to fetch Smart Price details.");
    }
  } catch (error) {
    console.error("Error in fetchSmartPrice:", error.message);
    throw new Error(
      error.response?.data?.message || error.message || "An error occurred."
    );
  }
};

export const fetchGetPrice = async (TUI) => {
  const token = localStorage.getItem("Token");
  const ClientID = localStorage.getItem("ClientID");
  if (!TUI) {
    throw new Error("TUI, ClientID, and Authorization token are required.");
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/get-spricer`, // Replace with your actual endpoint
      { TUI, ClientID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.Data;
  } catch (error) {
    console.error("Error in fetchGetPrice:", error.message);
    throw new Error(error || "An error occurred.");
  }
};
export const getTravelCheckList = async (TUI) => {
  const token = localStorage.getItem("Token");
  const ClientID = localStorage.getItem("ClientID");
  if (!TUI) {
    throw new Error("TUI, ClientID, and Authorization token are required.");
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/travelCheckList`, // Replace with your actual endpoint
      { TUI, ClientID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error in fetchGetPrice:", error.message);
    throw new Error(error || "An error occurred.");
  }
};

export async function startPayment(transactionID, netAmount, TUI) {
  const token = localStorage.getItem("Token");

  const payload = {
    TransactionID: transactionID,
    NetAmount: netAmount,
    TUI: TUI,
    ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
  };

  try {
    const response = await axios.post(
      `${FlightURL}/payment/start-pay`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Payment started successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error starting payment:", error);
    throw error;
  }
}

export async function retrieveBooking(ReferenceNumber) {
  const token = localStorage.getItem("Token");
  const TUI = localStorage.getItem("TUI");

  const data = {
    TUI,
    ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
    ReferenceNumber,
    ReferenceType: "T",
    ServiceType: "FLT",
  };

  try {
    // Send the POST request
    const response = await axios.post(
      `${FlightURL}/flight/retrieve-booking`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle the response
    console.log("Booking retrieved successfully:", response.data);
    return response.data; // Return the booking data
  } catch (error) {
    // Handle errors
    console.error("Error retrieving booking:", error);
    throw error; // You can handle the error as needed
  }
}

export async function fetchSSRData(TUI) {
  const trips = [
    {
      TUI: TUI,
      Amount: 0,
      OrderID: 1,
      Index: "",
    },
  ];
  const token = localStorage.getItem("Token");
  const clientID = "FVI6V120g22Ei5ztGK0FIQ=="; // Replace with your actual ClientID

  const data = {
    ClientID: clientID,
    PaidSSR: true,
    Source: "LV",
    Trips: trips,
  };

  try {
    // Send the POST request
    const response = await axios.post(`${FlightURL}/flight/ssr`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Handle the response
    console.log("SSR data retrieved successfully:", response.data);
    return response.data; // Return the SSR data
  } catch (error) {
    // Handle errors
    console.error("Error fetching SSR data:", error);
    throw error; // Propagate the error for further handling
  }
}
