import React, { useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useSearchParams } from "react-router-dom";
import { handleSeconds, HandelDay, HandelHoures } from "../../utils/index";
import { useTranslation } from "react-i18next";
import { LuDot } from "react-icons/lu";
import { IoAirplane } from "react-icons/io5";
import { useSmartPrice } from "../search/useSmartPrice";

const TicketCard = ({ ticket, currencyCode, TUI }) => {
  const { i18n } = useTranslation();
  const { handleFetchSmartPrice } = useSmartPrice();
  return (
    <>
      {ticket?.Segments.map((ticket, index) => (
        <div
          key={index}
          // onClick={() => handleFetchSmartPrice(ticket, TUI)}
          // // to={`/booking?index=${ticket?.Index}&bookingId=${TUI}`}
          className="  w-full rounded-md"
        >
          <div className="w-full rounded-lg border-l bg-white shadow-md">
            <div className="border-b py-5 px-5" key={ticket?.Index}>
              <p className="text-[13px] text-gray-600 font-bold">
                {HandelDay(ticket?.Flight.DepartureTime)}
              </p>
              <RouterItem route={ticket?.Flight} ticket={ticket?.Flight} />
            </div>
            <div className="">
              <div className="grid grid-cols-2 sm:grid-cols-5 justify-between gap-4 p-4 ">
                {/* Traveler's Name */}
                <div>
                  <h4 className="text-sm text-gray-500">Flight Number</h4>
                  <p className="font-semibold">{ticket?.Flight?.FlightNo}</p>
                </div>
                <div>
                  <h4 className="text-sm text-gray-500">PNR</h4>
                  <p className="font-semibold">
                    {ticket?.Flight?.APNR ? "-" : "_"}
                  </p>
                </div>
                <div>
                  <h4 className="text-sm text-gray-500">Airline</h4>
                  <p className="font-semibold">
                    {ticket?.Flight?.Airline.split("|")[0]}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="xl:block hidden py-5 px-5">
              <BaggageItem ticket={ticket} />
            </div> */}
          </div>
        </div>
      ))}
    </>
  );
};

export default TicketCard;

const RouterItem = ({ route, ticket }) => {
  const [DepAirport, DepCity] = ticket.DepAirportName.split(" |");
  const [ArrAirport, ArrCity] = ticket.ArrAirportName.split(" |");
  console.log(DepAirport);
  console.log(DepCity);
  console.log("route", route);
  return (
    <div className="pt-2">
      <div className="flex items-start gap-5">
        <div className="flex items-start w-full gap-2">
          <Direct
            houre={ticket.DepartureTime}
            city={DepCity}
            airpot={DepAirport}
            code={ticket?.DepartureCode}
          />
          <LineDot />
        </div>
        <div className="flex items-center gap-2 relative justify-center">
          <RouterType virtual_interlining={ticket.Alliances} />
          {/* <Image airline={ticket.AirlineName} /> */}
          <DurationTow duration={ticket.Duration} />
        </div>
        <div className="flex items-start w-full gap-2">
          <LinePlane />
          <Direct
            houre={ticket.ArrivalTime}
            city={ArrCity}
            airpot={ArrAirport}
            code={ticket?.ArrivalCode}
          />
        </div>
      </div>
      <Duration
        duration={ticket.Duration}
        houreFrom={ticket.DepartureTime}
        hoursTo={ticket.ArrivalTime}
      />
    </div>
  );
};

const Direct = ({ houre, city, airpot, code }) => {
  return (
    <div className="flex items-start flex-col w-full">
      <p className=" font-semibold text-sm"> {city}</p>
      <p className=" text-sm w-full">
        {airpot}({code})
      </p>
    </div>
  );
};

const Duration = ({ duration, houreFrom, hoursTo }) => {
  return (
    <div className="flex items-center justify-between pt-2">
      <p className="text-lg  font-semibold text-gray-600 pt-1">
        {HandelHoures(houreFrom)}
      </p>
      <p className="text-lg  font-semibold text-gray-600 pt-1">
        {HandelHoures(hoursTo)}
      </p>
    </div>
  );
};
const DurationTow = ({ duration, houreFrom, hoursTo }) => {
  return (
    <div className="text-sm text-gray-500 font-semibold flex items-center gap-1 absolute top-[120%] w-full justify-center">
      <p>{duration}</p>
    </div>
  );
};

const Image = ({ airline }) => {
  return (
    <img
      src={`//images.kiwi.com/airlines/32x32/${airline}.png?default=airline.png`}
      alt={airline}
      className="w-6 h-6 rounded-lg"
    />
  );
};

const RouterType = ({ virtual_interlining }) => {
  const { i18n } = useTranslation();
  return (
    <div className="w-fit">
      {virtual_interlining ? (
        <p className="bg-gray-200 py-[1px] px-2 text-sm rounded-lg">
          {i18n.language === "ar" ? "غير مباشر  " : "Not Direct"}
        </p>
      ) : (
        <p className="bg-gray-200 py-[1px] px-2 text-sm rounded-lg">
          {i18n.language === "ar" ? "مباشر" : "Direct"}{" "}
        </p>
      )}
    </div>
  );
};

const BaggageItem = ({ ticket }) => {
  return (
    <div className="flex items-center gap-3">
      {ticket?.Inclusions?.Baggage?.HandWeight && (
        <div className="flex items-center bg-gray-200 rounded-lg gap-2 py-1 px-2">
          <BsFillBagFill className="w-3 text-gray-800" />
          <p className="text-[13px] flex items-center gap-1 font-bold text-gray-600">
            <span>1X{ticket.Inclusions.Baggage.HandWeight}</span>
            <span>Kg</span>
          </p>
        </div>
      )}
      {ticket?.Inclusions?.Baggage?.HoldWeight && (
        <div className="flex items-center bg-gray-200 rounded-lg gap-2 py-1 px-2">
          <BsFillBagFill className="w-3 text-gray-800" />
          <p className="text-[13px] flex items-center gap-1 font-bold text-gray-600">
            <span>1X{ticket.Inclusions.Baggage.HoldWeight}</span>
            <span>Kg</span>
          </p>
        </div>
      )}
    </div>
  );
};

const LinePlane = () => {
  return (
    <div className=" w-full flex items-center gap-2">
      <div className="w-full h-[3px] rounded-full bg-gray-300" />{" "}
      <IoAirplane className="text-2xl text-gray-300 rotate-180" />
    </div>
  );
};
const LineDot = () => {
  return (
    <div className=" w-full flex items-center ">
      <LuDot className="text-3xl text-gray-300" />
      <div className="w-full h-[3px] rounded-full bg-gray-300" />
    </div>
  );
};
