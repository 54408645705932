import styled from "styled-components";

const StyledFormRow = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  position: relative;
  padding: 1.2rem 0;
  /* 
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  } */

  /* &:not(:last-child) {
    border-bottom: 1px solid #00000035;
  } */

  &:has(button) {
    display: flex;
    justify-content: flex-end;
    gap: 1.2rem;
  }
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 15px;
`;

const Error = styled.span`
  font-size: 1rem;
  color: #b91c1c;
  position: absolute;
  bottom: -10px;
`;

function FormRow({ label, error, children }) {
  return (
    <StyledFormRow>
      {label && <Label htmlFor={children.props.id}>{label}</Label>}
      {children}
      {error && <Error>{error?.message}</Error>}
    </StyledFormRow>
  );
}

export default FormRow;
