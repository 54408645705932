import React, { useEffect, useState } from "react";
import Input from "../../ui/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormRow from "../../ui/FormRow";
import { emailRegex, nameRegex, passportOrIdRegex } from "../../utils/regex";
import Select from "../../ui/Select";
import { countries, transformToTraveller } from "../../utils/helper";
import BirthDateSelect from "../../ui/BirthDateSelect";
import GenderButton from "../../ui/GenderButton";
import PhoneInput from "../../ui/PhoneInput";
import { createPaymentRequest, handlePayment } from "../../services/apiPayment";
import { useTranslation } from "react-i18next";
import { createItinerary } from "../../services/apiBooking";
import { fetchSSRData, startPayment } from "../../services/akbarApi";
import Baggages from "./Baggages";

export default function CustomerForm({ flights, travellerCheckList }) {
  const { t, i18n } = useTranslation();
  const ClientID = localStorage.getItem("ClientID");
  const [isLoading, setIsLoading] = useState(false);
  const { register, formState, handleSubmit } = useForm();
  const { errors } = formState;
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedBaggages, setSelectedBaggages] = useState([]);
  const [ssr, setSsr] = useState({ ssr: [], SSRNetAmountSum: 0 });
  const booking_token = localStorage.getItem("Token");
  const sessionId = searchParams.get("sessionId");
  console.log(ssr);
  const onSubmit = async (formValues) => {
    
    // setIsLoading(true);
    console.log(formValues);
    const travellers = transformToTraveller(formValues, travellerCheckList);
    const payload = {
      TUI: flights?.TUI,
      Travellers: travellers,
      NetAmount: flights?.NetAmount,
      ClientID,
    };
    console.log(travellers);
    try {
      const response = await createItinerary({
        TUI: flights?.TUI,
        ServiceEnquiry: "",
        ContactInfo: {
          Title: "Mr",
          FName: "John",
          LName: "Doe",
          Mobile: "8590055610",
          DestMob: "8590055610",
          Phone: "",
          Email: "john.doe@example.com",
          Language: "EN",
          Address: "MRRA 4 EDAPPALLY, Edappally",
          CountryCode: "IN",
          MobileCountryCode: "+91",
          DestMobCountryCode: "+91",
          State: "Kerala",
          City: "Cochin",
          PIN: "6865245",
          GSTCompanyName: "",
          GSTTIN: "",
          GSTMobile: "",
          GSTEmail: "",
          UpdateProfile: false,
          IsGuest: false,
        },
        DestinationContactInfo: {
          Address1: "",
          Address2: "",
          City: "",
          Mobile: "",
          Phone: "",
          Email: "",
          CountryCode: "",
          MobileCountryCode: "+91",
          State: "",
          PIN: "",
        },
        Travellers: travellers,
        PLP: [],
        SSR: ssr.ssr.length >= 1 ? ssr.ssr : null,
        CrossSell: [],
        CrossSellAmount: 0,
        EnableFareMasking: false,
        SSRAmount: ssr.SSRNetAmountSum,
        ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
        NetAmount: flights?.NetAmount,
        BRulesAccepted: "",
      });
      const itinerary = response.Data;

      if (itinerary) {
        const payment = await createPaymentRequest(itinerary?.NetAmount)
        console.log(payment)
        if(payment){
          window.location.href = payment.redirect_url;}
        // const test = await startPayment(
        //   itinerary?.TransactionID,
        //   itinerary?.NetAmount,
        //   itinerary?.TUI
        // );
        // console.log(test);
        // navigate(`/book/overview/${test.data?.TransactionID}`);
      }

      console.log("Itinerary created:", itinerary);
    } catch (error) {
      console.error("Error creating itinerary:", error);
    }
  };
  const renderPassengerForms = () => {
    const passengerTypes = [
      { type: "ADT", count: flights?.ADT || 0 },
      { type: "CHD", count: flights?.CHD || 0 },
      { type: "INF", count: flights?.INF || 0 },
    ];

    const checklist =
      travellerCheckList && travellerCheckList.length > 0
        ? travellerCheckList[0]
        : null;

    let paxCounter = 0; // Initialize a counter for unique paxID

    return passengerTypes.flatMap(({ type, count }) =>
      Array.from({ length: count }, (_, index) => {
        const paxID = ++paxCounter; // Increment counter to get a unique paxID

        return (
          <div
            key={`${type}-${index}`}
            className="mb-5 bg-white py-5 w-full border rounded-md px-5"
          >
            <div className="text-lg font-semibold px-5 pb-5 flex items-center justify-between border-b">
              <p>
                {t("passenger")} {index + 1} ({type})
              </p>
            </div>
            <Gender />
            <Row>
              <FormRow
                label={t("customerForm.firstname.label")}
                error={errors[`${type}-${index}-firstName`]}
              >
                <Input
                  {...register(`${type}-${index}-firstName`, {
                    required: t("customerForm.firstname.required"),
                    pattern: {
                      value: nameRegex,
                      message: t("customerForm.firstname.invalidFormat"),
                    },
                  })}
                  placeholder={t("customerForm.firstname.placeholder")}
                  err={!!errors[`${type}-${index}-firstName`]}
                />
              </FormRow>

              <FormRow
                label={t("customerForm.lastname.label")}
                error={errors[`${type}-${index}-lastName`]}
              >
                <Input
                  {...register(`${type}-${index}-lastName`, {
                    required: t("customerForm.lastname.required"),
                    pattern: {
                      value: nameRegex,
                      message: t("customerForm.lastname.invalidFormat"),
                    },
                  })}
                  placeholder={t("customerForm.lastname.placeholder")}
                  err={!!errors[`${type}-${index}-lastName`]}
                />
              </FormRow>
            </Row>

            <Row>
              {(checklist.DOB !== 0 || type == "CHD" || type == "INF") && (
                <BirthDateSelect
                  label={t("customerForm.birthday.label")}
                  register={register}
                  errors={errors}
                  id={`${type}-${index}-birthday`}
                  t={t}
                />
              )}
              {checklist.Nationality !== 0 && (
                <FormRow
                  label={t("customerForm.nationality.label")}
                  error={errors[`${type}-${index}-nationality`]}
                >
                  <Select
                    {...register(`${type}-${index}-nationality`, {
                      required: t("customerForm.nationality.required"),
                    })}
                    options={countries}
                    err={!errors[`${type}-${index}-nationality`]}
                  >
                    <option value="" disabled selected>
                      {t("customerForm.nationality.placeholder")}
                    </option>
                    {countries.map((country) => (
                      <option value={country.code} key={country.code}>
                        {country.name[i18n.language]} ({country.code})
                      </option>
                    ))}
                  </Select>
                </FormRow>
              )}
            </Row>

            <Row>
              {checklist.PassportNo !== 0 && (
                <FormRow
                  label={t("customerForm.passportOrIdNumber.label")}
                  error={errors[`${type}-${index}-id`]}
                >
                  <Input
                    {...register(`${type}-${index}-id`, {
                      required: t("customerForm.passportOrIdNumber.required"),
                      pattern: {
                        value: passportOrIdRegex,
                        message: t(
                          "customerForm.passportOrIdNumber.invalidFormat"
                        ),
                      },
                    })}
                    placeholder={t(
                      "customerForm.passportOrIdNumber.placeholder"
                    )}
                    err={!!errors[`${type}-${index}-id`]}
                  />
                </FormRow>
              )}
              {checklist.PDOE !== 0 && (
                <BirthDateSelect
                  label={t("customerForm.passportOrIdExpiryDate.label")}
                  register={register}
                  errors={errors}
                  id={`passportOrIdExpiryDate`}
                  t={t}
                />
              )}
            </Row>

            <Baggages
              paxID={paxID} // Pass the unique paxID here
              TUI={flights?.TUI}
              selectedBaggages={selectedBaggages}
              setSelectedBaggages={setSelectedBaggages}
              onSsr={setSsr}
            />
          </div>
        );
      })
    );
  };

  return (
    <>
      {isLoading && <Spinner />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <Header t={t} />
          <div className=" flex flex-col">{renderPassengerForms()}</div>

          <Contact register={register} errors={errors} />
          <div className="flex items-center justify-end">
            <button
              className="flex items-center bg-blue-700 text-white px-5 rounded-md font-semibold py-4 justify-center xl:w-auto w-full mt-5"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                t("paymentRedirect")
              ) : (
                <>
                  <div className="flex items-center gap-1 font-semibold">
                    {t("pay")}
                    <p className="flex items-center gap-[1px]">
                      {flights?.GrossAmount?.toFixed(2)}
                      <span className="text-sm">({flights?.CurrencyCode})</span>
                    </p>
                  </div>
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

const Header = ({ t }) => (
  <div className="text-lg font-semibold  px-5 pb-5 flex items-center justify-between">
    <p>{t("mainPassenger")}</p>
  </div>
);

const Contact = ({ errors, register }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-5 mt-5 bg-white border rounded-md mb-5">
      <div className="text-lg font-semibold border-b pb-5 flex items-center justify-between">
        <p className="px-5">{t("contactDetails")}</p>
      </div>
      <div className="py-5 px-5">
        <Row>
          <FormRow
            label={t("customerForm.email.label")}
            error={errors["email"]}
          >
            <Input
              {...register("email", {
                required: t("customerForm.email.required"),
                pattern: {
                  value: emailRegex,
                  message: t("customerForm.email.invalidFormat"),
                },
              })}
              placeholder={t("customerForm.email.placeholder")}
              err={!!errors["email"]}
            />
          </FormRow>
          <PhoneInput
            name="phone"
            label={t("customerForm.phone.label")}
            register={register}
            error={errors}
            t={t}
          />
        </Row>
      </div>
    </div>
  );
};
const Gender = () => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const genders = t("genders", { returnObjects: true });

  return (
    <div className="flex items-center pt-5 gap-5">
      {genders.map((gender, index) => (
        <GenderButton
          key={gender.value}
          active={gender.value === value ? true : false}
          onClick={() => setValue(gender.value)}
        >
          {gender.name}
        </GenderButton>
      ))}
    </div>
  );
};
const Row = ({ children }) => (
  <div className="flex items-center justify-between w-full xl:gap-8 xl:flex-row flex-col">
    {children}
  </div>
);

const Spinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center h-screen z-50 bg-opacity-70 backdrop-blur-sm">
      <div className="text-center bg-white shadow-md p-5 rounded-md">
        <div className="text-6xl mb-4">✈️</div>
        <p className="text-xl"> جاري معالجة الحجز ...</p>
      </div>
    </div>
  );
};
