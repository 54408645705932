import React from "react";
// import { logo } from "../assets/image";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const Logo = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <Link to={"/"}>
      <div className=" h-[40px] md:h-[50px] w-full">
        <img
          src={logo}
          alt="flymoon logo"
          className="w-full h-full object-contain"
        />
      </div>
    </Link>
  );
};

export default Logo;
