import React, { useState } from "react";
import { PiBagFill } from "react-icons/pi";
import { convertSSR } from "../../utils/helper";
import { fetchSSRData } from "../../services/akbarApi";

export default function Baggages({ TUI, paxID, onSsr }) {
  console.log(paxID);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedBaggages, setSelectedBaggages] = useState([]);
  const [ssr, setSsr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let baggageSSRs = [];

  const handleOpen = async () => {
    setIsPopupOpen(true);
    try {
      const res = await fetchSSRData(TUI);

      baggageSSRs = convertSSR(res.data, paxID);
      setSsr(baggageSSRs);
      setIsLoading(baggageSSRs);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  console.log("ssr", baggageSSRs);

  const addBaggage = (baggage) => {
    const selectedBaggage = {
      FUID: baggage.FUID,
      PaxID: baggage.PaxID,
      SSID: baggage.SSID,
    };

    onSsr((prevSsr) => ({
      ssr: [...prevSsr.ssr, selectedBaggage],
      SSRNetAmountSum: prevSsr.SSRNetAmountSum + baggage.SSRNetAmount,
    }));

    setSelectedBaggages([baggage]);
    setIsPopupOpen(false); // Optional: close the popup after adding
  };

  return (
    <div className="pt-5 mt-5 bg-white  rounded-md mb-5">
      <div className="text-lg font-semibold border-b pb-5 flex items-center justify-between px-5">
        <p>Bags</p>
      </div>
      <div className="py-5 px-5">
        {selectedBaggages.length === 0 ? (
          <div
            className="border rounded-full flex flex-col items-center justify-center w-[120px] h-[120px] hover:bg-gray-100 transition-colors cursor-pointer"
            onClick={() => handleOpen()}
            role="button"
            tabIndex={0}
            aria-label="Add Baggage"
          >
            <PiBagFill className="text-5xl" />
            <p className="text-sm">Add Baggage</p>
          </div>
        ) : (
          <div className="flex flex-col items-start justify-center gap-2 w-fit">
            {selectedBaggages?.map((baggage, index) => (
              <div
                key={index}
                className="border rounded-full flex items-center justify-between w-full py-2 px-4 cursor-pointer"
                onClick={() => handleOpen()}
              >
                <p>{baggage.Description}</p>
                <p>SAR {baggage.SSRNetAmount}</p>
              </div>
            ))}
          </div>
        )}
        {/* Conditionally render BaggageOptions */}
        {isPopupOpen && (
          <BaggageOptions
            baggageOptions={ssr}
            isLoading={isLoading}
            onClose={() => setIsPopupOpen(false)}
            addBaggage={addBaggage}
          />
        )}
      </div>
    </div>
  );
}

const BaggageOptions = ({ baggageOptions, onClose, addBaggage, isLoading }) => {
  if (isLoading)
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="text-white text-lg p-4 bg-gray-800 rounded-lg">
          Loading...
        </div>
      </div>
    );

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      aria-modal="true"
      role="dialog"
    >
      <div className="popup-container bg-white p-5 rounded-md shadow-md relative">
        <button
          onClick={onClose}
          className="close-btn text-4xl font-bold absolute top-2 right-4"
          aria-label="Close"
        >
          ×
        </button>
        <h2 className="text-2xl font-semibold text-center mb-10 mt-3">
          Select Baggage Option
        </h2>
        <div className="grid grid-cols-3 gap-10">
          {baggageOptions.map((option) => (
            <div
              key={option.SSID}
              className="flex flex-col gap-2 bg-gray-100 py-3 px-3 rounded-md"
            >
              <div>
                <p className="text-base">{option.Description}</p>
                <p className="text-gray-700 pt-2 text-center">
                  SAR {option.SSRNetAmount}
                </p>
              </div>
              <button
                className="add-btn bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                onClick={() => addBaggage(option)}
              >
                Add
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
