import axios from "axios";
const FlightURL = "https://apiflight.flymoons.com";

// export const checkFlights = async (body) => {
//   try {
//     const response = await axios.get(
//       "https://api.tequila.kiwi.com/v2/booking/check_flights",
//       {
//         params: {
//           ...body,
//           currency: "SAR",
//         },
//         headers: {
//           accept: "application/json",
//           apikey: "yTrA8Iq0rpFUzY3TgRRBOXFnljTlt2aM",
//         },
//       }
//     );

//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };
export const checkFlightsKiwi = async (body) => {
  try {
    const response = await axios.get(
      "https://api.tequila.kiwi.com/v2/booking/check_flights",
      {
        params: {
          ...body,
          currency: "SAR",
        },
        headers: {
          accept: "application/json",
          apikey: "yTrA8Iq0rpFUzY3TgRRBOXFnljTlt2aM",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createItinerary = async (payload) => {
  const token = localStorage.getItem("Token");
  console.log("create");
  try {
    // Validate input
    // if (!payload || !token) {
    //   throw new Error("Missing required parameters: payload or token.");
    // }

    // Send POST request to the backend
    const response = await axios.post(
      "https://apiflight.flymoons.com/flight/create-itinerary", // Backend API endpoint
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      }
    );
    console.log("res", response);
    // Check if the response is successful
    if (response.status === 200 && response.data) {
      console.log("Itinerary created successfully:", response.data);
      return response.data; // Return response data
    } else {
      throw new Error(response.data.error || "Failed to create itinerary.");
    }
  } catch (error) {
    console.error("Error in createItinerary:", error.error || error);
    throw error; // Re-throw the error for the caller to handle
  }
};
