import React, { useRef, useState, useCallback } from "react";
import { countriesPhones } from "../utils/helper";
import { useTranslation } from "react-i18next";
import Input from "./Input";
import FormRow from "./FormRow";
import styled from "styled-components";

const Error = styled.span`
  font-size: 1rem;
  color: #b91c1c;
  position: absolute;
  bottom: -10px;
`;

export default function PhoneInput({ name, label, register, error, t }) {
  return (
    <div className="w-full relative">
      <FormRow label={label} error={error}>
        <div className="flex items-center gap-3 w-full">
          <PhoneSelect id={"phone"} />
          <Input
            id={"phone"}
            className="w-full"
            {...register("phone", {
              required: t("customerForm.phone.required"),
            })}
            err={!!error["phone"]}
            placeholder={t("customerForm.phone.placeholder")}
          />
        </div>
      </FormRow>
      {error && <Error>{error["phone"]?.message}</Error>}
    </div>
  );
}

const PhoneSelect = React.memo(() => {
  const { i18n } = useTranslation();
  const refInput = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("+966");
  const [codePhone, setCodePhone] = useState("+966");

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const filteredCountries = countriesPhones.filter((phone) => {
    if (searchTerm) {
      return (
        phone["en"].toLowerCase().includes(searchTerm.toLowerCase()) ||
        phone["ar"].toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return phone;
  });

  const handleCountrySelect = useCallback((phone) => {
    setSearchTerm(phone.phone);
    setIsActive(false);
  }, []);

  return (
    <>
      <div
        className="rounded-md h-11 xl:w-[100px] max-w-[120px] w-fit flex items-center justify-center relative"
        onClick={() => {
          setIsActive(true);
          setSearchTerm("");
        }}
        role="button"
        tabIndex={0}
        aria-expanded={isActive}
      >
        <Input
          ref={refInput}
          value={searchTerm}
          onChange={handleSearchChange}
          readOnly={!isActive}
          err={false}
          aria-label="Country code selector"
        />
      </div>
      {isActive && (
        <ul
          className="w-full max-h-[200px] bg-white overflow-y-auto absolute left-0 top-[110%] z-10 flex flex-col border border-gray-200 rounded-md shadow-lg"
          role="listbox"
        >
          {filteredCountries.map((phone) => (
            <li
              value={phone.phone}
              className="cursor-pointer hover:bg-gray-100 px-3 py-3 font-medium text-gray-700 text-sm"
              key={phone.code}
              onClick={() => handleCountrySelect(phone)}
              role="option"
              aria-selected={codePhone === phone.phone}
            >
              {phone[i18n.language]}
              <span className="mr-2 text-gray-900 text-sm font-semibold">
                {phone.phone}
              </span>
            </li>
          ))}
        </ul>
      )}
    </>
  );
});
