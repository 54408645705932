import React from "react";
import { format } from "date-fns";
import {
  FaTicketAlt,
  FaCalendarAlt,
  FaClock,
  FaCheckCircle,
} from "react-icons/fa";

export default function TicketInfo({ bookingDetails, bookId }) {
  const formatDate = (dateString, type) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return type === "date"
      ? format(date, "yyyy-MM-dd")
      : format(date, "HH:mm:ss");
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <div>
        <h4 className="text-sm text-gray-500">Booking Number</h4>
        <p className="font-semibold">
          {bookingDetails?.TransactionID || "N/A"}
        </p>
      </div>
      <div>
        <h4 className="text-sm text-gray-500">Ticket Number</h4>
        <p className="font-semibold">{"00000" || "N/A"}</p>
      </div>
      <div>
        <h4 className="text-sm text-gray-500">Purchase Date</h4>
        <p className="font-semibold">
          {formatDate(bookingDetails?.HBReleaseDate, "date") || "N/A"}
        </p>
      </div>
      <div>
        <h4 className="text-sm text-gray-500">Order Time</h4>
        <p className="font-semibold">
          {formatDate(bookingDetails?.HBReleaseDate, "time") || "N/A"}
        </p>
      </div>
    </div>
  );
}
