import React from "react";
import useLocation from "../search/useLocation";
import {
  calculateDuration,
  HandelDay,
  HandelHoures,
  handleSeconds,
} from "../../utils";
import { BsDot } from "react-icons/bs";
import { IoMdAirplane } from "react-icons/io";
import Header from "./Header";
import { getTripsWithFare } from "../../utils/helper";

export default function TicketCard({
  origin,
  destination,
  arrival,
  departure,
  flights,
}) {
  const flight = getTripsWithFare(flights.Trips);
  console.log(flight);
  return (
    <>
      {flight[0]?.trip.map((flight) =>
        flight.Segments.map((flight, index) => (
          <div key={index}>
            <Header
              origin={flight?.Flight?.ArrivalCode}
              destination={flight?.Flight.DepartureCode}
            />
            <div className=" bg-white w-full  rounded-md xl:py-4 xl:px-3 flex flex-col gap-5 xl:border">
              <SegmentStop
                code={flight?.Flight.DepartureCode}
                date={flight?.Flight.DepartureTime}
                style="dot"
              />
              <div className="grid grid-cols-[70px,40px,1fr] items-center">
                <Duration duration={flight.Flight?.Duration} />
                {/* <Duration arrival={arrival} departure={departure} /> */}
                <div className="flex items-center justify-center text-gray-900">
                  <IoMdAirplane className="text-lg rotate-180" />
                </div>
                {/*  <AirLine airline={flight} />*/}
              </div>
              <SegmentStop
                code={flight.Flight?.ArrivalCode}
                date={flight.Flight?.ArrivalTime}
                style="dot_2"
              />
            </div>
          </div>
        ))
      )}
    </>
  );
}

const SegmentStop = ({ code, date, style }) => {
  return (
    <div className="grid grid-cols-[70px,40px,1fr] items-center">
      <Date date={date} />
      <div
        className={`flex items-center justify-center text-gray-600 ${style} relative`}
      >
        <BsDot className={`text-3xl `} />
      </div>
      <Direction code={code} />
    </div>
  );
};

const Direction = ({ code }) => {
  const [cityName, airport, isLoading] = useLocation(code);
  return (
    <div className="flex-1 pr-2">
      {isLoading ? (
        // Skeleton loading animation
        <div className="animate-pulse">
          <p className="h-4 bg-gray-300 rounded w-3/4 mb-2"></p>{" "}
          <p className="h-3 bg-gray-300 rounded w-1/2"></p>{" "}
        </div>
      ) : (
        // Actual content
        <>
          <p className="text-[14px] font-bold">
            {cityName}.{code}
          </p>
          <p className="text-[12px] pt-1">{airport}</p>
        </>
      )}
    </div>
  );
};

const Date = ({ date }) => {
  return (
    <div className="flex flex-col gap-1 ">
      <p className=" text-sm font-semibold ">{HandelHoures(date)}</p>
      <p className=" text-xs text-gray-700 ">{HandelDay(date)}</p>
    </div>
  );
};

const AirLine = ({ airline }) => {
  return (
    <div className="flex bg-gray-50 items-center h-6 w-fit rounded-lg shadow-md">
      <img
        className="rounded-lg h-full"
        src={`//images.kiwi.com/airlines/32x32/${airline?.operating_airline.iata}.png?default=airline.png`}
        alt={airline?.operating_airline.iata}
      />
      <p className="text-sm px-1"> {airline?.airline?.Name}</p>
    </div>
  );
};

const Duration = ({ arrival, departure, duration }) => {
  const { hours, minutes } = calculateDuration(arrival, departure);

  return (
    <div className="flex items-center font-semibold text-sm  text-gray-800">
      <p>{duration}</p>
      {/* <p>{hours}h</p>:<p>{minutes}m</p> */}
    </div>
  );
};
