import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { retrieveBooking } from "../services/akbarApi";
import TicketInfo from "../features/bookingDetails/TicketInfo";
import PassengerInfo from "../features/bookingDetails/PassengerInfo";
import TicketCard from "../features/bookingDetails/TicketCard";
import Spinner from "../ui/Spinner";
// import TicketCard from "../features/search/TicketCard";

export default function BookingOverview() {
  const { bookId } = useParams(); // Get the dynamic bookId from the URL
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await retrieveBooking(bookId); // Fetch booking data
        setBookingDetails(data.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to retrieve booking details.");
        setLoading(false);
      }
    };

    if (bookId) {
      fetchBooking();
    }
  }, [bookId]);

  // Loading and error states
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }
  console.log(bookingDetails);
  // Render the booking details
  return (
    <div className="min-h-screen pb-5  pt-20 max-w-screen-xl mx-auto px-10">
      {/* <TicketInfo bookingDetails={bookingDetails.data} bookId={bookId} /> */}
      <div className="w-full max-w-4xl p-6 bg-white shadow-lg rounded-lg border border-gray-200 mx-auto space-y-6">
        {/* Header Section */}
        <TicketInfo bookingDetails={bookingDetails} bookId={bookId} />
        {/* Traveler Information */}
        {/* <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div>
            <h4 className="text-sm text-gray-500">Traveler's Name</h4>
            <p className="font-semibold">
              {bookingDetails?.travelerName || "N/A"}
            </p>
          </div>
          <div>
            <h4 className="text-sm text-gray-500">Date of Birth</h4>
            <p className="font-semibold">{bookingDetails?.dob || "N/A"}</p>
          </div>
          <div>
            <h4 className="text-sm text-gray-500">Nationality</h4>
            <p className="font-semibold">
              {bookingDetails?.nationality || "N/A"}
            </p>
          </div>
          <div>
            <h4 className="text-sm text-gray-500">Passport Number</h4>
            <p className="font-semibold">
              {bookingDetails?.passportNumber || "N/A"}
            </p>
          </div>
        </div> */}
        <PassengerInfo
          passengers={bookingDetails?.Pax}
          ssrList={bookingDetails?.SSR}
        />
        {/* Flight Details */}
        <h2 className="text-xl font-bold text-gray-700 mb-4">
          Trips Information
        </h2>
        {bookingDetails?.Trips?.map((trips) =>
          trips.Journey.map((ticket, index) => (
            <TicketCard
              ticket={ticket}
              key={index}
              currencyCode={bookingDetails?.CurrencyCode}
              TUI={bookingDetails?.TUI}
            />
          ))
        )}
        {/* {bookingDetails?.Trips[0]?.Journey?.map((ticket) => (
          <TicketCard
            ticket={ticket}
            key={ticket.Index}
            currencyCode={bookingDetails?.CurrencyCode}
            TUI={bookingDetails?.TUI}
          />
        ))} */}
        {/* <div className="p-4 bg-gray-50 rounded-lg space-y-4">
          <div className="flex justify-between items-center">
            <div className="text-center">
              <p className="text-xl font-bold">
                {bookingDetails?.departureTime || "N/A"}
              </p>
              <p className="text-sm text-gray-500">
                {bookingDetails?.departureDate || "N/A"}
              </p>
            </div>
            <div className="text-center">
              <p className="text-sm text-gray-500">Duration</p>
              <p className="text-red-500">
                {bookingDetails?.duration || "N/A"}
              </p>
            </div>
            <div className="text-center">
              <p className="text-xl font-bold">
                {bookingDetails?.arrivalTime || "N/A"}
              </p>
              <p className="text-sm text-gray-500">
                {bookingDetails?.arrivalDate || "N/A"}
              </p>
            </div>
          </div>

          <div className="flex justify-between items-start">
            <div>
              <h4 className="font-semibold">
                {bookingDetails?.departureCity || "N/A"}
              </h4>
              <p className="text-sm text-gray-500">
                {bookingDetails?.departureAirport || "N/A"}
              </p>
            </div>
            <div>
              <h4 className="font-semibold">
                {bookingDetails?.arrivalCity || "N/A"}
              </h4>
              <p className="text-sm text-gray-500">
                {bookingDetails?.arrivalAirport || "N/A"}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
            <div>
              <h4 className="text-sm text-gray-500">Flight Number</h4>
              <p className="font-semibold">
                {bookingDetails?.flightNumber || "N/A"}
              </p>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">Aviation Category</h4>
              <p className="font-semibold">
                {bookingDetails?.category || "Economy"}
              </p>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">Seat Number</h4>
              <p className="font-semibold">
                {bookingDetails?.seatNumber || "N/A"}
              </p>
            </div>
            <div>
              <h4 className="text-sm text-gray-500">Baggage</h4>
              <p className="font-semibold">
                {bookingDetails?.baggage || "N/A"}
              </p>
            </div>
          </div>
        </div> */}
        {/* Price Section */}
        <div className="flex justify-between items-center">
          <div>
            <h4 className="text-sm text-gray-500">Price</h4>
            <p className="text-lg font-semibold text-blue-600">
              ${bookingDetails?.GrossAmount || "N/A"}
            </p>
          </div>
          <button className="text-blue-500 hover:underline">
            Show All Price Details
          </button>
        </div>
      </div>
    </div>
  );
}
