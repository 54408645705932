import React from "react";

export default function PassengerInfo({ passengers, ssrList }) {
  const calculateAge = (dob) => {
    if (!dob) return "N/A";
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--; // Adjust age if the current month/day is before the birth month/day
    }

    return age;
  };

  return (
    <div className="w-full ">
      <h2 className="text-xl font-bold text-gray-700 mb-4">
        Passenger Information
      </h2>

      <div className="space-y-6">
        {passengers.map((pax, index) => {
          // Filter SSRs for the current passenger
          const passengerSSR = ssrList.filter((ssr) => ssr.PaxId == pax.PaxID);

          return (
            <div
              key={pax.PaxID}
              className="grid grid-cols-2 sm:grid-cols-5 justify-between gap-4 p-4 bg-gray-50 rounded-lg shadow-md border"
            >
              {/* Traveler's Name */}
              <div>
                <h4 className="text-sm text-gray-500">Traveler's Name</h4>
                <p className="font-semibold">
                  {pax.FName} {pax.LName}
                </p>
              </div>

              {/* Date of Birth */}
              <div>
                <h4 className="text-sm text-gray-500">Date of Birth</h4>
                <p className="font-semibold">{pax.DOB}</p>
              </div>

              {/* Nationality */}
              <div>
                <h4 className="text-sm text-gray-500">Nationality</h4>
                <p className="font-semibold">{pax.Nationality || "N/A"}</p>
              </div>

              {/* Passport Number */}
              <div>
                <h4 className="text-sm text-gray-500">Passport Number</h4>
                <p className="font-semibold">{pax.PassportNo || "N/A"}</p>
              </div>

              {/* Age */}
              <div>
                <h4 className="text-sm text-gray-500">Age</h4>
                <p className="font-semibold">{calculateAge(pax.DOB)}</p>
              </div>

              {/* SSRs (Baggage and Meals) */}
              {passengerSSR.length > 0 && (
                <div className="col-span-2">
                  <h4 className="text-sm text-gray-500">Special Requests</h4>
                  <ul className="list-disc list-inside">
                    {passengerSSR.map((ssr, idx) => (
                      <li key={idx} className="font-S text-sm">
                        {ssr.Code === "BAG" || "XBPB"
                          ? ssr.Code === "BAG"
                            ? `Baggage: ${ssr.Description}`
                            : `Extra Baggage: ${ssr.Description}`
                          : ssr.Code}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
