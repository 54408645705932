import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Price from "../features/booking/Price";
import Stepper from "../ui/Stepper";
import Spinner from "../ui/Spinner";
import CustomertInfo from "../features/booking/CustomertInfo";
import Payment from "../features/booking/Payment";
import FlightsDetails from "../features/booking/FlightsDetails";
import CustomerForm from "../features/booking/CustomerForm";
import { checkFlights } from "../services/apiFlights";
import {
  fetchGetPrice,
  fetchSmartPrice,
  fetchSSRData,
  getTravelCheckList,
} from "../services/akbarApi";
import NoResult from "../ui/NoResult";
import { useSearchFlights } from "../context/SearchFlightsContext";
import Baggages from "../features/booking/Baggages";

export default function BookingPayment({ TUI }) {
  const [ssr, setSsr] = useState([]);
  const [flights, setFlights] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [travellerCheckList, setTravellerCheckList] = useState([]);

  useEffect(() => {
    let delayTimeout; // Declare timeout variable for cleanup

    async function getData() {
      setIsLoading(true);
      setError(null); // Reset error state before fetching

      try {
        const res = await fetchGetPrice(TUI);
        console.log("GetPrice response:", res);
        const travelCheckListData = await getTravelCheckList(res?.TUI); // Call your API
        setTravellerCheckList(travelCheckListData?.TravellerCheckList || []);
        console.log(travelCheckListData);
        setFlights(res);
      } catch (err) {
        console.error("Error fetching flights:", err);
        setError("Failed to fetch flight details. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }

    // Set a 30-second delay before fetching data
    delayTimeout = setTimeout(() => {
      getData();
    }, 3000);

    // Cleanup timeout when the component unmounts or TUI changes
    return () => clearTimeout(delayTimeout);
  }, [TUI]);

  if (isLoading) return <Spinner />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!flights) return <NoResult />;

  return (
    <div className="grid xl:grid-cols-[1fr,0.4fr] gap-10 relative">
      <div className="flex flex-col gap-5">
        <FlightsDetails flights={flights} />
        <CustomerForm
          flights={flights}
          ssr={ssr}
          travellerCheckList={travellerCheckList}
        />

        {/* Future step implementation */}
        {/* {step === 1 ? <CustomerForm flights={flights} /> : <Payment />} */}
      </div>
      <Price flights={flights} />
    </div>
  );
}
